import React from 'react';
import { graphql } from 'gatsby';
import ApproachSection from 'components/ApproachSection';
import HomeHero from 'components/HomeHero';
import CampaignSection from 'components/CampaignSection';
import MembersSection from 'components/MembersSection';
import ActualitiesSection from 'components/ActualitiesSection';
import HistorySection from 'components/HistorySection';
import PartnersSection from 'components/PartnersSection';
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import VisibleSectionsProvider from '../providers/VisibleSectionsProvider';
import GallerySection from '../components/GallerySection';
import GettingInvolvedSection from '../components/GettingInvolvedSection';
import SideMenu from '../components/SideMenu';

const Homepage = ({ data }) => {
  const {
    allDatoCmsActuality,
    datoCmsHomepage,
    allDatoCmsPartner,
    datoCmsGlobal,
    allDatoCmsMember,
    datoCmsGallery,
    datoCmsGettingInvolvedPage,
    datoCmsMainMenu,
    datoCmsSideMenu,
    allDatoCmsArticle,
  } = data;

  return (
    <Layout
      globalData={datoCmsGlobal}
      mainMenuData={datoCmsMainMenu}
      engagementKinds={datoCmsGettingInvolvedPage?.involvementKind}
      variant="homePage"
    >
      <Seo
        title={datoCmsHomepage?.metaTags?.title}
        description={datoCmsHomepage?.metaTags?.description}
      />
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        <VisibleSectionsProvider>
          <SideMenu data={datoCmsSideMenu} />
          <HomeHero
            data={datoCmsHomepage}
            articleCardData={allDatoCmsArticle}
          />
          <CampaignSection data={datoCmsHomepage} />
          <ApproachSection data={datoCmsHomepage} />
          <GettingInvolvedSection data={datoCmsHomepage} variant="homepage" />
          <HistorySection data={datoCmsHomepage} />
          <MembersSection members={allDatoCmsMember?.edges ?? []} />
          <ActualitiesSection
            data={datoCmsHomepage}
            actualities={allDatoCmsActuality?.edges ?? []}
          />
          <GallerySection
            pics={datoCmsGallery?.pictures?.slice(0, 16) ?? []}
            title={datoCmsHomepage?.galleryTitle}
            overTitle={datoCmsHomepage?.galleryOvertitle}
            variant="homepage"
          />
        </VisibleSectionsProvider>
      </div>
      <PartnersSection
        data={datoCmsHomepage}
        partners={allDatoCmsPartner?.edges ?? []}
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    allDatoCmsActuality(sort: { order: DESC, fields: meta___createdAt }) {
      edges {
        node {
          avatar {
            url
            alt
          }
          content
          job
          name
          ctaUrl
          ctaName
        }
      }
    }
    allDatoCmsMember(sort: { fields: position, order: ASC }) {
      edges {
        node {
          avatar {
            alt
            url
          }
          description
          fullname
          image {
            url
            alt
          }
          role
        }
      }
    }
    allDatoCmsPartner {
      edges {
        node {
          id
          name
          logo {
            url
            alt
            ... on DatoCmsFileField {
              gatsbyImageData
            }
          }
        }
      }
    }
    datoCmsGettingInvolvedPage {
      gettingInvolvedOvertitle
      gettingInvolvedTitle
      gettingInvolvedContent
      involvementKind {
        title
        description
        information
        cta
        ctaLink
        icon {
          url
        }
      }
    }
    datoCmsHomepage {
      metaTags {
        description
        title
      }
      mainTitle
      mainDescription
      mainDescriptionDetails
      galleryTitle
      galleryOvertitle
      goalsYearTitle
      cloudCardDescription
      campaign {
        nextDeparture
        deadline(formatString: "DD MMMM YYYY", locale: "fr")
        departureTime
        goal
        goalPercent
        baseline
        buttonName
        updateString
        updateDate
      }
      steps {
        description
        icon {
          url
        }
      }
      approachOvertitle
      approachTitle
      etapes {
        description
        icon {
          url
        }
      }
      historyOvertitle
      historyTitle
      historySubtitle
      keyDates {
        date
        content
        picture {
          alt
          url
        }
      }
      actualitiesOvertitle
      actualitiesTitle
      partnersOvertitle
      partnersTitle
      gettingInvolvedOvertitle
      gettingInvolvedTitle
      gettingInvolvedContent
      involvementKind {
        title
        description
        information
        cta
        ctaLink
        icon {
          url
        }
      }
    }
    datoCmsGlobal {
      facebookLink
      instagramLink
      linkedinLink
      mailContact
      twitterLink
      footerText
      terms
    }
    datoCmsGallery {
      pictures {
        isImage
        alt
        title
        url
        width
        height
        ... on DatoCmsFileField {
          gatsbyImageData
        }
      }
    }
    datoCmsMainMenu {
      blogLink
      galleryLink
      homepageLink
      involvmentLink
    }
    datoCmsSideMenu {
      actualitiesSectionLink
      approachSectionLink
      campaignSectionLink
      gallerySectionLink
      goalSectionLink
      heroLink
      historySectionLink
      involvmentSectionLink
      membersSectionLink
    }
    allDatoCmsArticle(
      limit: 1
      sort: { order: DESC, fields: meta___createdAt }
    ) {
      edges {
        node {
          meta {
            createdAt(formatString: "DD MMM YYYY", locale: "fr")
          }
          title
          slug
          description
          picture {
            url
            alt
            ... on DatoCmsFileField {
              gatsbyImageData
            }
          }
          category {
            name
            slug
          }
        }
      }
    }
  }
`;

export default Homepage;
