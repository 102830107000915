import React, { useRef, useState } from 'react';
import { Stack, Text, Container } from '@tymate/margaret';
import styled from 'styled-components';
import { Section, Overtitle, Subtitle } from 'ui';
import {
  useDeepCompareEffect,
  useWindowScroll,
  useWindowSize,
} from 'react-use';
import IntersectionSensor from 'components/IntersectionSensor';

const ApproachWrapper = styled(Stack).attrs({
  size: 'full',
  direction: 'column',
})`
  position: relative;
  z-index: 1;
`;

const ApproachText = styled(Text).attrs({
  type: 'bodyLarge',
  paddingTop: 1,
})`
  width: 254px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  text-shadow: 0 -1px 0 ${({ theme }) => theme.primary},
    0 1px 0 ${({ theme }) => theme.primary},
    0 -2px 0 ${({ theme }) => theme.primary},
    0 2px 0 ${({ theme }) => theme.primary},
    -1px -1px 0 ${({ theme }) => theme.primary},
    1px -1px 0 ${({ theme }) => theme.primary},
    -1px 1px 0 ${({ theme }) => theme.primary},
    1px 1px 0 ${({ theme }) => theme.primary},
    -2px -2px 0 ${({ theme }) => theme.primary},
    2px -2px 0 ${({ theme }) => theme.primary},
    -2px 2px 0 ${({ theme }) => theme.primary},
    2px 2px 0 ${({ theme }) => theme.primary};
`;

const IconOutter = styled.div`
  position: relative;
  width: 130px;
  height: 130px;

  &:before {
    position: absolute;
    z-index: 0;
    content: '${({ index }) => index + 1}';
    width: 50px;
    height: 50px;
    border-radius: 999em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    font-weight: 800;
    color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.colors.yellowLight};
    top: 0;
    left: -28px;
    font-family: ${({ theme }) => theme.fonts.system};
  }
`;

const IconInner = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 100%;
  padding: ${({ theme }) => theme.spacing()};
  z-index: 2;
`;

const Curve = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;

  ${({ theme }) => theme.media.tablet`
    top: -${theme.sidemenuWrapperHeight};
  `}
`;

const Path = styled.path`
  stroke: #fbcca1;
  stroke-dasharray: 10px;
  stroke-linecap: round;
  stroke-width: 2px;
`;

const Mask = styled.path`
  stroke: #ffffff;
  stroke-width: 2px;
`;

const alignX = modulo => {
  switch (modulo) {
    case 0:
      return 'flex-start';
    default:
      return 'flex-end';
  }
};

const ApproachSection = ({ data }) => {
  const containerRef = useRef();
  const contentRef = useRef();
  const maskPathRef = useRef();
  const [coordinates, setCoordinates] = useState([]);
  const { y: windowScrollY } = useWindowScroll();
  const containerOffset = containerRef.current?.offsetTop;
  const contentOffset = contentRef.current?.offsetTop;
  const contentHeight = contentRef.current?.offsetHeight;
  const offset = containerOffset + contentOffset;
  const { height: windowHeight } = useWindowSize();
  const pathLength = maskPathRef.current?.getTotalLength();

  const scrolledRatio = Math.min(
    1,
    Math.max(
      0,
      (windowScrollY + windowHeight / 2 - offset) /
        (contentHeight - windowHeight / 2),
    ),
  );

  useDeepCompareEffect(() => {
    if (Boolean(containerRef.current)) {
      setCoordinates(
        (data?.etapes || []).map((_, index) => {
          const elementCoordinates = document
            .querySelector(`#approach-${index}`)
            .getBoundingClientRect();

          return [
            (elementCoordinates.left || 0) + 130 / 2,
            windowScrollY +
              (elementCoordinates.top || 0) -
              (containerOffset || 0) +
              130 / 2,
          ];
        }),
      );
    }
  }, [{ data, containerOffset }]);

  return (
    <Section
      backgroundColor="primary"
      color="#ffffff"
      id="comment-ca-marche"
      ref={containerRef}
      overflowHidden
    >
      <Container size="narrow">
        <IntersectionSensor section="#comment-ca-marche">
          <Stack direction="column" alignX="center" gap={0.5} paddingBottom={4}>
            <Overtitle>{data?.approachOvertitle}</Overtitle>
            <Subtitle color="white">{data?.approachTitle}</Subtitle>
          </Stack>

          <Stack
            direction="column"
            width="100%"
            gap={{ default: 2, tablet: 4 }}
            ref={contentRef}
          >
            {(data?.etapes || []).map(({ description, icon }, index) => (
              <ApproachWrapper
                alignX={
                  index === 0 || index === data?.etapes?.length - 1
                    ? 'center'
                    : alignX(index % 2)
                }
                gap={{ default: 2, desktop: 1 }}
                key={index}
              >
                <Stack direction="column" alignX="center">
                  <IconOutter index={index}>
                    <IconInner id={`approach-${index}`}>
                      <img src={icon?.url} alt="" />
                    </IconInner>
                  </IconOutter>

                  <ApproachText>{description}</ApproachText>
                </Stack>
              </ApproachWrapper>
            ))}
          </Stack>

          <Curve
            viewBox={`0 0 ${containerRef.current?.offsetWidth} ${containerRef.current?.offsetHeight}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <mask id="mask" maskUnits="userSpaceOnUse">
                <Mask
                  d={`M${coordinates
                    .map(([x, y], index) =>
                      index === 0 ? `${x} ${y}` : `${x} ${y - 200}, ${x} ${y}`,
                    )
                    .join('S')}`}
                  style={{
                    strokeDasharray: pathLength,
                    strokeDashoffset:
                      pathLength * (1 - Math.floor(100 * scrolledRatio) / 100),
                  }}
                  ref={maskPathRef}
                />
              </mask>
            </defs>
            <g id="maskReveal" mask="url(#mask)">
              <Path
                d={`M${coordinates
                  .map(([x, y], index) =>
                    index === 0 ? `${x} ${y}` : `${x} ${y - 200}, ${x} ${y}`,
                  )
                  .join('S')}`}
              />
            </g>
          </Curve>
        </IntersectionSensor>
      </Container>
    </Section>
  );
};

export default ApproachSection;
