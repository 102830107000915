import React from 'react';
import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Tag } from '../ui/base';
import { Title } from 'ui';
import cloudWhite from 'images/cloud-white.svg';

const Wrapper = styled(Stack).attrs({
  direction: 'column',
  paddingHorizontal: 0.75,
  paddingTop: 0,
  paddingBottom: 0.75,
  alignX: 'center',
  alignY: 'space-between',
  gutterSize: 0.75,
  width: '18rem',
  height: '18rem',
})`
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  border-radius: 20px;
  position: relative;
  justify-self: center;

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
  }

  transition: box-shadow 0.2s ease-in-out;
`;

const TextStack = styled(Stack).attrs({
  direction: 'column',
  gutterSize: 0.5,
  flex: '1 0 auto',
})`
  background-color: transparent;
  display: block;
  overflow: hidden;
`;

const InfoStack = styled(Stack).attrs({
  direction: 'row',
  alignX: 'flex-start',
  alignY: 'baseline',
  gutterSize: 0.5,
})``;

const ArticleTitle = styled(Title)`
  font-size: 1.2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ArticleOverTitle = styled.div`
  ${({ theme }) => theme.fontStyles.bodySmall};
  text-align: 'flex-start';
  color: ${({ theme }) => theme.textSecondary};
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ArticleDate = styled.div`
  font-size: 1rem;
  padding-bottom: ${({ theme }) => theme.spacing(0.75)};
  line-height: 1em;
  max-height: 1em;
  overflow: hidden;
  text-transform: uppercase;
  color: ${({ theme }) => theme.textSecondary};
`;

const CloudText = styled(Title)`
  font-size: 1.2rem;
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  text-overflow: ellipsis;
  overflow: hidden;
  transform: rotate(10deg);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Cloud = styled.div`
  width: 100%;
  max-width: 174px;
  height: 110px;
  background-image: url(${cloudWhite});
  top: -40px;
  right: -30px;
  position: absolute;
  z-index: 2;
  transform: rotate(5deg);
  -webkit-filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.24));
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.24));
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArticleCard = data => {
  const articles = data?.data?.edges ?? [];
  const picture = articles[0]?.node?.picture;
  const category = articles[0]?.node?.category.name;
  const date = articles[0]?.node?.meta?.createdAt;
  const title = articles[0]?.node?.title;
  const description = articles[0]?.node?.description;

  return (
    <Wrapper>
      <Cloud>
        <CloudText textAlign="left">Dernière actu</CloudText>
      </Cloud>
      <GatsbyImage
        image={getImage(picture)}
        alt={picture?.alt || picture?.title || ''}
        style={{ overflow: 'hidden', borderRadius: '20px' }}
      />
      <TextStack>
        <InfoStack>
          <Tag>{category}</Tag>
          <ArticleDate>{date}</ArticleDate>
        </InfoStack>
        <ArticleTitle>{title}</ArticleTitle>
        <ArticleOverTitle id="objectifs">{description}</ArticleOverTitle>
      </TextStack>
    </Wrapper>
  );
};

export default ArticleCard;
