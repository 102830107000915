import React, { useState } from 'react';
import { Stack, Text, Container, Avatar } from '@tymate/margaret';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useEffectOnce } from 'react-use';
import { Section, Overtitle, Subtitle } from 'ui';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { MdFiberManualRecord } from 'react-icons/md';
import IntersectionSensor from 'components/IntersectionSensor';
import yellowCloud from 'images/yellow-head-cloud.svg';
import greenCloud from 'images/green-head-cloud.svg';
import orangeCloud from 'images/red-head-cloud.svg';
import Truncate from 'react-truncate';

const ActualityWrapper = styled(Stack).attrs({
  direction: 'column',
})`
  position: relative;
  height: 485px;
  padding-top: ${({ theme }) => theme.spacing(5)};
  padding-right: ${({ theme }) => theme.spacing(0.5)};
  padding-left: ${({ theme }) => theme.spacing(0.5)};

  > img {
    width: 100%;
  }
`;

const CloudImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  padding-right: ${({ theme }) => theme.spacing(0.5)};
  padding-left: ${({ theme }) => theme.spacing(0.5)};
`;

const Name = styled(Text)`
  ${({ theme }) => theme.fontStyles.heading};
  font-family: ${({ theme }) => theme.fonts.title};
`;

const Actuality = styled(Stack).attrs({
  direction: 'column',
  paddingHorizontal: 1.5,
  paddingBottom: 2,
  alignX: 'center',
  gutterSize: 0.5,
  size: 'full',
})`
  height: 100%;
  color: #fff;
  text-align: center;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
`;

const ArrowButton = styled(Stack).attrs({ alignX: 'center', alignY: 'center' })`
  width: 48px;
  height: 48px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  border-radius: 48px;
  z-index: 1;
  transition: opacity 0.2s ease-in-out;
  display: flex !important;
  top: 50%;
  background-color: ${({ theme }) => theme.tertiary};
  color: #ffffff;

  &:hover {
    background-color: var(--colors-corail-600);
    color: #ffffff;
  }

  > svg {
    font-size: 24px;
    display: flex;
  }

  &:before {
    display: none;
  }

  &.slick-prev {
    ${({ theme }) => theme.media.tablet`
      left: -64px;
    `}
  }

  &.slick-next {
    ${({ theme }) => theme.media.tablet`
      right: -64px;
    `}
  }

  &.slick-disabled {
    opacity: 0.5;
    cursor: default;
  }

  transition: background-color 0.2s ease-in-out;
`;

const AnchorLink = styled.a`
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: ${({ color }) => color};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 74px;
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing(0.5)};

  &:hover {
    background-color: ${({ bgColorHover }) => bgColorHover};
    color: ${({ theme }) => theme.colors.white};
  }
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
`;

const Dots = styled(Stack).attrs({
  direction: 'row',
  alignX: 'center',
  gap: 1,
})`
  position: absolute;
  bottom: -64px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;

const CLOUDS = [
  {
    src: greenCloud,
    color: 'var(--colors-green-600)',
    bgColorHover: 'var(--colors-green-800)',
  },
  {
    src: yellowCloud,
    color: 'var(--ui-secondary)',
    bgColorHover: 'var(--colors-yellow-700)',
  },
  {
    src: orangeCloud,
    color: 'var(--colors-orange)',
    bgColorHover: 'var(--colors-corail-700)',
  },
];

const ActualitiesSection = ({ data, actualities }) => {
  const [isInitialized, setIsInitialized] = useState();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    adaptiveHeight: true,
    slidesToShow: Math.min(3, actualities.length),
    slidesToScroll: Math.min(3, actualities.length),
    initialSlide: 0,
    prevArrow: (
      <ArrowButton>
        <BsChevronLeft />
      </ArrowButton>
    ),
    nextArrow: (
      <ArrowButton>
        <BsChevronRight />
      </ArrowButton>
    ),
    appendDots: dots => (
      <Dots>
        {dots.map(({ key, props }) => (
          <MdFiberManualRecord
            key={key}
            size={10}
            color={
              props?.className === 'slick-active'
                ? '#E88062'
                : 'rgba(232, 128, 98, 0.35)'
            }
          />
        ))}
      </Dots>
    ),
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffectOnce(() => {
    setIsInitialized(true);
  });

  if (!isInitialized) {
    return null;
  }

  return (
    <Section style={{ backgroundColor: '#ffffff' }} id="actualites">
      <Container
        paddingHorizontal={{ default: 1, tablet: 0 }}
        style={{ width: '1050px' }}
      >
        <IntersectionSensor section="#actualites">
          <Stack direction="column" gap={{ default: 2, desktop: 4 }}>
            <Stack
              direction="column"
              alignX="center"
              gap={0.5}
              size="full"
              paddingBottom={2}
            >
              <Overtitle>{data?.actualitiesOvertitle}</Overtitle>
              <Subtitle>{data?.actualitiesTitle}</Subtitle>
            </Stack>
            <Slider {...settings}>
              {actualities.map(({ node }, index) => {
                const cloudIndex = index % 3;

                return (
                  <ActualityWrapper key={index}>
                    <CloudImage src={CLOUDS[cloudIndex].src} alt="" />

                    <Actuality
                      style={{ backgroundColor: CLOUDS[cloudIndex].color }}
                    >
                      <div>
                        <Avatar size="huge" imageUrl={node?.avatar?.url} />
                      </div>
                      <Stack
                        direction="column"
                        alignX="center"
                        gap={0.125}
                        size="full"
                        paddingTop={0.5}
                      >
                        <div>
                          <Text
                            type="body"
                            style={{
                              textTransform: 'uppercase',
                              fontWeight: 400,
                            }}
                          >
                            {node?.job}
                          </Text>
                        </div>
                        <div>
                          <Name>{node?.name}</Name>
                        </div>
                      </Stack>
                      <Stack
                        direction="column"
                        size="full"
                        alignX="center"
                        alignY="space-between"
                        gutterSize={1}
                        style={{ height: '100%' }}
                      >
                        <div>
                          <Text
                            type="body"
                            style={{
                              flexWrap: 'wrap',
                              color: 'var(--colors-whiteAlpha-900)',
                            }}
                          >
                            <Truncate lines={4}>{node?.content}</Truncate>
                          </Text>
                        </div>
                        {node?.ctaName && node?.ctaUrl && (
                          <AnchorLink
                            color={CLOUDS[cloudIndex].color}
                            bgColorHover={CLOUDS[cloudIndex].bgColorHover}
                            href={node?.ctaUrl}
                            target="_blank"
                            rel="nofollow noopener noreferer"
                          >
                            <div>{node?.ctaName}</div>
                            <div>
                              <BsChevronRight
                                size={12}
                                style={{ marginLeft: '0.5rem' }}
                              />
                            </div>
                          </AnchorLink>
                        )}
                      </Stack>
                    </Actuality>
                  </ActualityWrapper>
                );
              })}
            </Slider>
          </Stack>
        </IntersectionSensor>
      </Container>
    </Section>
  );
};

export default ActualitiesSection;
