import React, { useContext } from 'react';
import { Stack } from '@tymate/margaret';
import styled, { css } from 'styled-components';
import { VisibleSectionsContext } from '../providers/VisibleSectionsProvider';

const SideNav = styled(Stack)`
  position: sticky;
  background-color: transparent;
  margin-left: ${({ theme }) => theme.spacing(0.5)};

  ${({ theme }) => theme.media.desktop`
    margin-left: ${({ theme }) => theme.spacing(2)};
  `}
`;

const AnchorLink = styled.a`
  font-size: 14px;
  width: max-content;
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 74px;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing(0.75)};

  &:hover {
    background: ${({ theme }) => theme.colors.corail[50]};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &,
      &:hover {
        background-color: ${({ theme }) => theme.tertiary};
        color: #ffffff;
      }
    `}

  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
`;

const Wrapper = styled(Stack)`
  max-width: 180px;
  position: sticky;
  top: 64px;
  z-index: 4;
  left: 0px;
  display: none;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.sidemenuWrapperHeight};

  ${({ theme }) => theme.media.tablet`
    display: block;
  `};
`;

export const SideMenu = ({ data }) => {
  const { visibleSections } = useContext(VisibleSectionsContext);
  const visibleSection = visibleSections.find(
    ({ isIntersecting }) => isIntersecting,
  );

  const sections = [
    {
      title: data?.heroLink,
      ref: '#presentation',
    },
    {
      title: data?.goalSectionLink,
      ref: '#objectifs',
    },
    {
      title: data?.campaignSectionLink,
      ref: '#campagne',
    },
    {
      title: data?.approachSectionLink,
      ref: '#comment-ca-marche',
    },
    {
      title: data?.involvmentSectionLink,
      ref: '#actions',
    },
    {
      title: data?.historySectionLink,
      ref: '#histoire',
    },
    {
      title: data?.membersSectionLink,
      ref: '#membres',
    },
    {
      title: data?.actualitiesSectionLink,
      ref: '#actualites',
    },
    {
      title: data?.gallerySectionLink,
      ref: '#photos',
    },
  ];

  return (
    <Wrapper>
      <SideNav direction="column" alignX="space-between" gap={0.75}>
        {sections.map(({ ref, title }) => (
          <AnchorLink
            key={ref}
            href={ref}
            isActive={ref === visibleSection?.section}
          >
            {title}
          </AnchorLink>
        ))}
      </SideNav>
    </Wrapper>
  );
};

export default SideMenu;
