import React, { useState, useEffect, useRef } from 'react';
import { useIntersection } from 'react-use';
import { Container as RawContainer, Stack, Text } from '@tymate/margaret';
import styled, { useTheme } from 'styled-components';
import ToysAnimation from './ToysAnimation';
import Birds from '../images/toys-animation/birds.png';
import {
  Section,
  Title as RawTitle,
  Overtitle,
  Subtitle as RawSubtitle,
} from 'ui';
import { capitalize } from 'lodash';
import IntersectionSensor from 'components/IntersectionSensor';

const Campaign = styled(Section).attrs({
  paddingVertical: { default: 0, tablet: 0, desktop: 0 },
})`
  padding-bottom: ${({ theme }) => theme.spacing(4)};
  ${({ theme }) => theme.media.desktop`
    padding-bottom: ${({ theme }) => theme.spacing(6)};
  `};
`;

const Container = styled(RawContainer).attrs({
  marginBottom: { default: 0, tablet: -18 },
})`
  width: 100%;
  max-width: 720px;
`;

const TextWrapper = styled(Stack).attrs({
  direction: 'column',
  alignX: 'center',
  size: 'full',
  gap: 2,
})`
  padding: 0;
  position: relative;
  z-index: 3;
  text-align: center;
`;

const BirdsImage = styled.img.attrs({
  src: Birds,
  alt: 'birds',
})`
  position: absolute;
  width: 200px;
  top: -100px;
  left: 32px;
  z-index: 4;

  ${({ theme }) => theme.media.tablet`
    width: 250px;
    top: -150px;
    left: 35px;
  `}

  ${({ theme }) => theme.media.desktop`
    width: 300px;
    top: 150px;
    left: 100px;
    margin-left: -100px;
  `}
`;

const ActButton = styled.a.attrs({ href: '#actions' })`
  text-decoration: none;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  background-color: ${({ theme }) => theme.colors.yellow[500]};
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing(0.75)};
  border-radius: ${({ theme }) => theme.button.borderRadius};

  &:hover {
    background-color: ${({ theme }) => theme.tertiary};
  }

  transition: background-color 0.2s ease-in-out;
`;

const Title = styled(RawTitle)`
  padding-top: ${({ theme }) => theme.spacing(0.75)};
  padding-bottom: ${({ theme }) => theme.spacing(0.75)};
`;

const SubTitle = styled(RawSubtitle)`
  font-size: 1.4rem;
  padding-bottom: ${({ theme }) => theme.spacing(0.75)};
`;

const CampaignSection = ({ data }) => {
  const theme = useTheme();
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });
  const [progression, setProgression] = useState(0);
  const goalPercent = data?.campaign?.goalPercent || 0;
  const isIntersecting = intersection?.isIntersecting;

  useEffect(() => {
    if (isIntersecting && progression === 0) {
      setProgression(goalPercent);
    }
  }, [isIntersecting, progression, goalPercent]);

  const nextDeparture = new Intl.DateTimeFormat('fr', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(new Date(data?.campaign?.departureTime));

  const lastUpdate = new Intl.DateTimeFormat('fr', {
    day: 'numeric',
    month: 'numeric',
  }).format(new Date(data?.campaign?.updateDate));

  return (
    <Campaign backgroundColor="white" id="campagne">
      <IntersectionSensor section="#campagne">
        <Container>
          <TextWrapper>
            <Overtitle>{data?.campaign?.nextDeparture}</Overtitle>
            <Title>{capitalize(nextDeparture)}</Title>
            <SubTitle>{`${data?.campaign?.goal} ${progression}%`}</SubTitle>
            <Text
              type="body"
              style={{ color: theme.textSecondary }}
            >{`${data?.campaign?.updateString} ${lastUpdate}`}</Text>
            <Stack alignY="baseline" gap={1}>
              <Text type="bodyLarge">{data?.campaign?.baseline}</Text>
              <ActButton>{data?.campaign?.buttonName}</ActButton>
            </Stack>
          </TextWrapper>
        </Container>
        <div style={{ position: 'relative' }}>
          <BirdsImage />
        </div>
        <div ref={intersectionRef} />
        <ToysAnimation progression={progression} />
      </IntersectionSensor>
    </Campaign>
  );
};

export default CampaignSection;
