import * as React from 'react';
import { useState, useRef } from 'react';
import { useEffectOnce, useDeepCompareEffect } from 'react-use';
import {
  Engine,
  Composite,
  Runner,
  Render,
  World,
  Bodies,
  Body,
  Mouse,
  MouseConstraint,
} from 'matter-js';
import styled from 'styled-components';
import Sea from '../images/toys-animation/sea.png';
import Wave from '../images/toys-animation/wave.png';
import Bowl from '../images/toys-animation/bowl.png';
import Basketball from '../images/toys-animation/basketball.png';
import Book from '../images/toys-animation/book.png';
import Box from '../images/toys-animation/box.png';
import Car from '../images/toys-animation/car.png';
import Dog from '../images/toys-animation/dog.png';
import Present from '../images/toys-animation/present.png';

const DEFAULT_THICKNESS = 200;
const FALLING_DISTANCE = 4000;
const NUMBER_WALLS = 3;
const TOY_SIZE = 30;
const TOY_BOUNCINESS = 0.95;
const TOY_FRICTION_AIR = 0.01;
const TOYS = [
  { texture: Basketball, restitution: 1, frictionAir: 1, size: 1.1 },
  { texture: Book, restitution: 0.75, frictionAir: 2.25, size: 1 },
  { texture: Box, restitution: 0.8, frictionAir: 1.5, size: 1 },
  { texture: Car, restitution: 0.75, frictionAir: 1, size: 1 },
  { texture: Dog, restitution: 0.9, frictionAir: 1.75, size: 1.2 },
  { texture: Present, restitution: 0.75, frictionAir: 1.75, size: 1 },
];

const calculateSeaXScale = x => {
  const ratio = (x - 768) / (1200 - 768);
  const interval = 2 - 1;
  return ratio * interval + 1;
};

const calculateBowlXScale = x => {
  const ratio = (x - 768) / (1200 - 768);
  const interval = 1 - 0.65;
  return ratio * interval + 0.65;
};

const calculateToyScale = x => {
  const ratio = (x - 768) / (1200 - 768);
  const interval = 0.565 - 0.425;
  return ratio * interval + 0.45;
};

const calculateToySize = x => {
  const ratio = (x - 768) / (1200 - 768);
  const interval = 37 - 30;
  return ratio * interval + 30;
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: none;

  ${({ theme }) => theme.media.tablet`
    display: block;
  `}
`;

const Container = styled.div`
  position: relative;
  height: 850px;
  width: min(100%, 1200px);
  margin: 0 auto;
`;

const CanvasWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Canvas = styled.canvas``;

const createBowl = opacity => {
  return Bodies.rectangle(0, 0, DEFAULT_THICKNESS, DEFAULT_THICKNESS, {
    collisionFilter: {
      group: -1,
      category: 2,
      mask: 0,
    },
    isStatic: true,
    render: {
      opacity,
      sprite: {
        texture: Bowl,
        xScale: 1,
        yScale: 1.3,
      },
    },
  });
};

const createWave = () => {
  return Bodies.rectangle(0, 0, DEFAULT_THICKNESS, DEFAULT_THICKNESS, {
    collisionFilter: {
      group: -1,
      category: 2,
      mask: 0,
    },
    isStatic: true,
    render: {
      sprite: {
        texture: Wave,
        xScale: 1,
        yScale: 1,
      },
    },
  });
};

const createRandomObject = width => {
  const randomIndex = Math.floor(Math.random() * TOYS.length);
  const [minX, maxX] = [
    width / 2.85 - TOY_SIZE * 2,
    width / 2.85 + TOY_SIZE * 2,
  ];

  const randomX = Math.floor(Math.random() * maxX) + minX;
  const randomY =
    Math.floor(Math.random() * (FALLING_DISTANCE - TOY_SIZE)) + TOY_SIZE;

  if (randomIndex === 0) {
    return Bodies.circle(
      randomX,
      -randomY,
      (TOYS[randomIndex].size * calculateToySize(width)) / 1.5,
      {
        frictionAir: TOYS[randomIndex].frictionAir * TOY_FRICTION_AIR,
        sleepThreshold: 5,
        restitution: TOYS[randomIndex].restitution * TOY_BOUNCINESS,
        render: {
          strokeStyle: '#ffffff',
          sprite: {
            texture: TOYS[randomIndex].texture,
            xScale: calculateToyScale(width),
            yScale: calculateToyScale(width),
          },
        },
      },
    );
  } else {
    return Bodies.rectangle(
      randomX,
      -randomY,
      TOYS[randomIndex].size * calculateToySize(width),
      TOYS[randomIndex].size * calculateToySize(width),
      {
        frictionAir: TOYS[randomIndex].frictionAir * TOY_FRICTION_AIR,
        sleepThreshold: 5,
        restitution: TOYS[randomIndex].restitution * TOY_BOUNCINESS,
        render: {
          strokeStyle: '#ffffff',
          sprite: {
            texture: TOYS[randomIndex].texture,
            xScale: calculateToyScale(width),
            yScale: calculateToyScale(width),
          },
        },
      },
    );
  }
};

const updateEnvironmentPosition = (
  width,
  height,
  sea,
  ceiling,
  leftPartBowl,
  rightPartBowl,
) => {
  sea.render.sprite.xScale = calculateSeaXScale(width);

  Body.setPosition(sea, {
    x: width / 2,
    y: height / 1.01,
  });

  Body.setPosition(ceiling, {
    x: width / 2,
    y: -FALLING_DISTANCE,
  });

  Body.setPosition(leftPartBowl, {
    x: width / 3.625,
    y: height / 1.4,
  });

  Body.setPosition(rightPartBowl, {
    x: width - width / 3.625,
    y: height / 1.4,
  });

  Body.setVertices(sea, [
    { x: 0, y: height },
    { x: width * 2, y: height },
    { x: width * 2, y: height + DEFAULT_THICKNESS },
    { x: 0, y: height + DEFAULT_THICKNESS },
  ]);

  Body.setVertices(ceiling, [
    { x: 0, y: 0 },
    { x: width * 2, y: 0 },
    { x: width * 2, y: 0 + DEFAULT_THICKNESS },
    { x: 0, y: 0 + DEFAULT_THICKNESS },
  ]);

  Body.setVertices(leftPartBowl, [
    { x: DEFAULT_THICKNESS / 10, y: 0 },
    { x: DEFAULT_THICKNESS / 6, y: 0 },
    { x: DEFAULT_THICKNESS / 5 + DEFAULT_THICKNESS / 5, y: height / 2 },
    { x: DEFAULT_THICKNESS / 5, y: height / 2 },
  ]);

  Body.setVertices(rightPartBowl, [
    { x: DEFAULT_THICKNESS / 4.6, y: 0 },
    { x: DEFAULT_THICKNESS / 3.5, y: 0 },
    { x: DEFAULT_THICKNESS / 5, y: height / 2 },
    { x: 0, y: height / 2 },
  ]);
};

const updateBowlPosition = (width, height, bowl) => {
  bowl.render.sprite.xScale = calculateBowlXScale(width);

  Body.setPosition(bowl, {
    x: width / 2,
    y: height / 1.5,
  });

  Body.setVertices(bowl, [
    { x: 0, y: height / 1.9 },
    { x: width / 1.85, y: height / 1.9 },
    { x: width / 1.85, y: height + DEFAULT_THICKNESS / 2 },
    { x: 0, y: height + DEFAULT_THICKNESS / 2 },
  ]);
};

const updateWavePosition = (width, height, wave) => {
  wave.render.sprite.xScale = calculateSeaXScale(width);
  Body.setPosition(wave, {
    x: width / 2,
    y: height / 1.05,
  });

  Body.setVertices(wave, [
    { x: 0, y: height },
    { x: width * 2, y: height },
    { x: width * 2, y: height + DEFAULT_THICKNESS },
    { x: 0, y: height + DEFAULT_THICKNESS },
  ]);
};

const ToysAnimation = ({ progression }) => {
  const boxRef = useRef(null);
  const canvasRef = useRef(null);
  const [constraints, setConstraints] = useState();
  const [scene, setScene] = useState();

  const handleResize = () => {
    setConstraints(boxRef.current.getBoundingClientRect());
  };

  useEffectOnce(() => {
    const engine = Engine.create({ enableSleeping: true });
    const render = Render.create({
      element: boxRef.current,
      engine: engine,
      canvas: canvasRef.current,
      options: {
        showSleeping: false,
        background: '#ffffff',
        wireframes: false,
      },
    });

    const sea = Bodies.rectangle(0, 0, DEFAULT_THICKNESS, DEFAULT_THICKNESS, {
      isStatic: true,
      render: {
        opacity: 1,
        sprite: {
          texture: Sea,
          xScale: 2,
          yScale: 2,
        },
      },
    });

    const walls = Array.apply(null, { length: NUMBER_WALLS }).map(_ =>
      Bodies.rectangle(0, 0, 0, DEFAULT_THICKNESS, {
        isStatic: true,
        render: {
          fillStyle: 'deepskyblue',
          visible: false,
        },
      }),
    );
    const backgroundBowl = createBowl(0.95);
    const bowl = createBowl(0.5);
    const wave = createWave();

    World.add(engine.world, [sea, ...walls, backgroundBowl, bowl, wave]);

    let mouse = Mouse.create(render.canvas),
      mouseConstraint = MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0.4,
          render: {
            visible: false,
          },
        },
      });

    mouse.element.removeEventListener('mousedown', mouse.mousewheel);
    mouse.element.removeEventListener('mouseup', mouse.mousewheel);
    mouse.element.removeEventListener('mousemove', mouse.mousewheel);
    mouse.element.removeEventListener('mousewheel', mouse.mousewheel);
    mouse.element.removeEventListener('DOMMouseScroll', mouse.mousewheel);
    World.add(engine.world, mouseConstraint);

    Runner.run(engine);
    Render.run(render);

    setConstraints(boxRef.current.getBoundingClientRect());
    setScene(render);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useDeepCompareEffect(() => {
    if (constraints) {
      const { width, height } = constraints;

      scene.bounds.max.x = width;
      scene.bounds.max.y = height;
      scene.options.width = width;
      scene.options.height = height;
      scene.canvas.width = width;
      scene.canvas.height = height;

      let [
        sea,
        ceiling,
        leftPartBowl,
        rightPartBowl,
        backgroundBowl,
        ...restBodies
      ] = scene.engine.world.bodies;
      let bowl = restBodies[restBodies.length - 2];
      let wave = restBodies[restBodies.length - 1];

      updateEnvironmentPosition(
        width,
        height,
        sea,
        ceiling,
        leftPartBowl,
        rightPartBowl,
      );
      updateBowlPosition(width, height, backgroundBowl);
      updateBowlPosition(width, height, bowl);
      updateWavePosition(width, height, wave);
    }
  }, [{ scene, constraints }]);

  useDeepCompareEffect(() => {
    if (scene) {
      const { width, height } = constraints;
      const toys = Array.apply(null, {
        length: progression,
      }).map(_ => createRandomObject(width));

      const bodies = scene.engine.world.bodies;
      const oldBowl = bodies[bodies.length - 2];
      const oldWave = bodies[bodies.length - 1];
      Composite.remove(scene.engine.world, [oldBowl, oldWave]);

      World.add(scene.engine.world, toys);

      let bowl = createBowl(0.5);
      updateBowlPosition(width, height, bowl);
      World.add(scene.engine.world, bowl);

      const wave = createWave();
      updateWavePosition(width, height, wave);
      World.add(scene.engine.world, wave);
    }
  }, [{ scene, progression }]);

  return (
    <Wrapper>
      <Container>
        <CanvasWrapper ref={boxRef}>
          <Canvas ref={canvasRef} />
        </CanvasWrapper>
      </Container>
    </Wrapper>
  );
};

export default ToysAnimation;
