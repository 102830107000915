import React from 'react';
import { Stack, Text, Container, Box } from '@tymate/margaret';
import { Overtitle } from 'ui';
import Engagement from './Engagement';
import { Section } from '../ui/base';
import IntersectionSensor from 'components/IntersectionSensor';

const GettingInvolvedContent = ({ data }) => (
  <Stack
    gap={{ default: 5, desktop: 20 }}
    direction={{ default: 'column', desktop: 'row' }}
    alignX="center"
    alignY="center"
  >
    <Stack
      direction="column"
      gap={0.5}
      flex={{ default: 1, desktop: 1 }}
      alignX={{ default: 'center', desktop: 'flex-start' }}
      style={{
        maxWidth: '420px',
        minWidth: '280px',
        margin: 0,
      }}
    >
      <Overtitle textAlign="left">{data?.gettingInvolvedOvertitle}</Overtitle>
      <Text type="h2" style={{ whiteSpace: 'pre-wrap' }} textAlign="center">
        {data?.gettingInvolvedTitle}
      </Text>
      <div
        style={{ maxWidth: '45em' }}
        dangerouslySetInnerHTML={{
          __html: data?.gettingInvolvedContent,
        }}
      />
    </Stack>

    <Box
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Stack
        flex={1}
        alignY="center"
        alignX="center"
        gap={1}
        direction={{ default: 'column', medium: 'row' }}
      >
        <Engagement index={0} engagement={data?.involvementKind?.[0]} />

        <Box>
          <Stack direction="column" gap={1}>
            {(data?.involvementKind ?? []).slice(1).map((engagement, index) => (
              <Engagement
                key={index}
                index={index + 1}
                engagement={engagement}
              />
            ))}
          </Stack>
        </Box>
      </Stack>
    </Box>
  </Stack>
);

const GettingInvolvedSection = ({ data, variant }) => {
  return (
    <Section variant={variant} id="actions">
      <Container>
        {variant === 'homepage' ? (
          <IntersectionSensor section="#actions">
            <GettingInvolvedContent data={data} />
          </IntersectionSensor>
        ) : (
          <GettingInvolvedContent data={data} />
        )}
      </Container>
    </Section>
  );
};

export default GettingInvolvedSection;
