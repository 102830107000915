import styled, { css } from 'styled-components';
import { Stack, Box, ButtonReset, Container } from '@tymate/margaret';
import GoalSection from './GoalSection';
import { Title, Section } from 'ui';
import cloudsFar from 'images/clouds-far.svg';
import cloudsClose from 'images/clouds-close.svg';
import IntersectionSensor from './IntersectionSensor';
import React from 'react';
import ArticleCard from './ArticleCard';
import { navigate } from 'gatsby';

const HeroWrapper = styled(Stack).attrs({
  alignY: { default: 'space-between', medium: 'center' },
  alignX: { default: 'center', medium: 'space-between' },
  size: 'full',
  direction: { default: 'column', medium: 'row' },
  paddingTop: { default: 3, tablet: 0 },
  paddingBottom: { default: 20, tablet: 22, medium: 20, desktop: 30 },
  paddingHorizontal: { default: 0, tablet: 3 },
  gap: 2,
})``;

const HeroText = styled(Stack).attrs({
  direction: 'column',
  alignX: 'flex-start',
  alignY: 'flex-start',
  size: 'default',
  padding: 1,
  paddingTop: 0,
})`
  max-width: 600px;
`;

const Description = styled.div`
  ${({ theme }) => theme.fontStyles.bodyLarge};
  text-align: flex-start;
  flex-wrap: wrap;

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      color: ${({ theme }) => theme.text};
    `}

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      color: ${({ theme }) => theme.textSecondary};
    `}
`;

const Clouds = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${({ zIndex }) => zIndex};
  background-image: url(${({ backgroundImage }) => backgroundImage});
  height: 80vh;
  background-position: top center;
  background-size: cover;
`;

const HeroSection = styled(Section)`
  padding-top: 0;
  margin-top: 2rem;

  ${({ theme }) => theme.media.tablet`
    margin-top: -29rem;
  `}

  ${({ theme }) => theme.media.desktop`
    margin-top: -31rem;
  `}
`;

const HomeHero = ({ data, articleCardData }) => {
  const handleClick = () => {
    const lastArticleSlug = articleCardData?.edges[0].node.slug;
    navigate(`/blog/${lastArticleSlug}`);
  };

  return (
    <HeroSection backgroundColor="background">
      <Container style={{ paddingTop: 0 }}>
        <HeroWrapper>
          <HeroText>
            <IntersectionSensor section="#presentation">
              <Title>{data?.mainTitle}</Title>
              <Description
                variant="primary"
                dangerouslySetInnerHTML={{ __html: data?.mainDescription }}
              />
            </IntersectionSensor>
            <Description
              variant="secondary"
              dangerouslySetInnerHTML={{
                __html: data?.mainDescriptionDetails,
              }}
            />
          </HeroText>
          <ButtonReset
            onClick={() => {
              handleClick();
            }}
          >
            <ArticleCard data={articleCardData}></ArticleCard>
          </ButtonReset>
        </HeroWrapper>
      </Container>

      <Clouds zIndex={1} backgroundImage={cloudsFar} />
      <Clouds zIndex={3} backgroundImage={cloudsClose} />
      <GoalSection data={data} />
    </HeroSection>
  );
};

export default HomeHero;
