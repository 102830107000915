import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useEffectOnce } from 'react-use';
import { Stack, Container, Avatar, Text, useMargaret } from '@tymate/margaret';
import { Section } from 'ui';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { MdFiberManualRecord } from 'react-icons/md';
import IntersectionSensor from 'components/IntersectionSensor';

const ArrowButton = styled(Stack).attrs({ alignX: 'center', alignY: 'center' })`
  cursor: pointer;
  display: flex !important;
  width: 48px;
  height: 48px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  border-radius: 48px;
  z-index: 3;
  transition: opacity 0.2ms ease-in-out;
  background: ${({ theme }) => theme.tertiary};
  color: #ffffff;
  flex-shrink: 0;

  > svg {
    font-size: 24px;
    display: flex;
  }

  &:before {
    display: none;
  }

  &:hover {
    background-color: var(--colors-corail-600);
  }

  &.slick-disabled {
    opacity: 0.5;
    cursor: default;
  }

  ${({ variant }) =>
    variant === 'left' &&
    css`
      transform: translateX(20px) translateY(+200px);
      ${({ theme }) => theme.media.tablet`
          transform: translateX(20px) translateY(0);
  `}
    `};

  ${({ variant }) =>
    variant === 'right' &&
    css`
      transform: translateX(-20px) translateY(+200px);
      ${({ theme }) => theme.media.tablet`
        transform: translateX(-20px) translateY(0);
      `}
    `};

  transition: background-color 0.2s ease-in-out;
`;

const Member = styled(Stack).attrs({
  direction: 'column',
  paddingHorizontal: { default: 2, tablet: 3 },
  paddingVertical: { default: 2, tablet: 1 },
  alignX: 'center',
  gutterSize: 0.5,
})`
  border-radius: 999em;
  background-color: ${({ theme }) => theme.background};
  width: 100%;
  max-width: 632px;
  min-height: 600px;
  text-align: center;
  z-index: 2;
  ${({ theme }) => theme.media.tablet`
    height: 632px;
    width: 432px;
  `}
  ${({ theme }) => theme.media.medium`
    height: 632px;
    width: 532px;
  `}
  ${({ theme }) => theme.media.desktop`
    height: 632px;
    width: 632px;
  `}
`;

const MemberWrapper = styled(Stack).attrs({})`
  position: relative;
  transform: translateY(-140px);
  padding: 0 ${({ theme }) => theme.spacing(1)};

  ${({ theme }) => theme.media.tablet`
  align-items: center;
`};

  ${({ theme }) => theme.media.medium`
  transform : translateY(0);
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0;
`};
`;

const PictureWrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.media.tablet`
  width: 100%;
`}

  ${({ theme }) => theme.media.medium`
    width: 60%;
    padding-bottom: ${({ theme }) => theme.spacing(10)}
  `}

  ${({ theme }) => theme.media.desktop`
    width: 50%;
    padding-bottom: ${({ theme }) => theme.spacing(6)}
  `}
`;

const Picture = styled.div`
  width: 100%;
  background-position: center center;
  background-size: cover;
  padding-top: 100%;

  ${({ theme }) => theme.media.medium`
    padding-top: 125.86%;
  `}

  ${({ imageUrl }) =>
    Boolean(imageUrl) &&
    css`
      background-image: url(${imageUrl});
    `}
`;

const Name = styled.div`
  ${({ theme }) => theme.fontStyles.h2};
  font-size: 1.5rem;
`;

const Description = styled.div`
  ${({ theme }) => theme.fontStyles.body};
  flex-wrap: wrap;

  p {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
    margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  }
`;

const Slide = styled(Stack).attrs({
  size: 'full',
  position: 'relative',
  direction: 'column',
  alignX: { default: 'center', medium: 'start' },
})``;

const MembersSection = ({ members }) => {
  const [isInitialized, setIsInitialized] = useState();
  const { isMobile } = useMargaret();
  const sliderRef = useRef(null);

  const settings = {
    dots: isMobile ? false : true,

    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: null,
    nextArrow: null,
    appendDots: dots => (
      <Stack gap={1} alignX="center">
        {dots.map(({ key, props }) => (
          <MdFiberManualRecord
            key={key}
            size={10}
            color={
              props?.className === 'slick-active'
                ? '#E88062'
                : 'rgba(232, 128, 98, 0.35)'
            }
          />
        ))}
      </Stack>
    ),
  };

  useEffectOnce(() => {
    setIsInitialized(true);
  });

  if (!isInitialized) {
    return null;
  }

  const handleLeftArrowClick = () => {
    sliderRef?.current?.slickPrev();
  };

  const handleRightArrowClick = () => {
    sliderRef?.current?.slickNext();
  };

  return (
    <Section style={{ backgroundColor: '#ffffff' }} id="membres" overflowHidden>
      <Container>
        <IntersectionSensor section="#membres">
          <Slider ref={sliderRef} {...settings}>
            {members.map(({ node }, index) => (
              <div key={index}>
                <Slide key={index}>
                  <PictureWrapper>
                    <Picture
                      imageUrl={node?.image?.url}
                      alt={node?.image?.alt}
                    />
                  </PictureWrapper>
                  <MemberWrapper id="member">
                    <ArrowButton onClick={handleLeftArrowClick} variant="left">
                      <BsChevronLeft />
                    </ArrowButton>
                    <Member>
                      <Avatar size="huge" imageUrl={node?.avatar?.url} />
                      <div>
                        <Text
                          type="bodyLarge"
                          style={{ textTransform: 'uppercase' }}
                        >
                          {node?.role}
                        </Text>
                        <Name>{node?.fullname}</Name>
                      </div>

                      <Description
                        dangerouslySetInnerHTML={{ __html: node?.description }}
                      />
                    </Member>
                    <ArrowButton
                      onClick={handleRightArrowClick}
                      variant="right"
                    >
                      <BsChevronRight />
                    </ArrowButton>
                  </MemberWrapper>
                </Slide>
              </div>
            ))}
          </Slider>
        </IntersectionSensor>
      </Container>
    </Section>
  );
};

export default MembersSection;
