import React from 'react';
import styled, { css } from 'styled-components';
import { Stack, Text, Container } from '@tymate/margaret';
import cloud from 'images/cloud.svg';
import { Section as RawSection, Overtitle, Subtitle } from 'ui';
import Zoom from 'react-medium-image-zoom';
import IntersectionSensor from 'components/IntersectionSensor';
import 'react-medium-image-zoom/dist/styles.css';

const Section = styled(RawSection)`
  border-top: 5rem solid ${({ theme }) => theme.background};
  margin-top: -3px;
`;

const Heading = styled(Stack).attrs({
  direction: 'column',
  alignX: 'center',
  alignY: 'center',
  size: 'full',
  gutterSize: 0.5,
  paddingHorizontal: 1,
})`
  margin-top: ${({ theme }) => theme.spacing(-10)};
  margin-bottom: ${({ theme }) => theme.spacing(-2)};
  padding-left: ${({ theme }) => theme.spacing(3)};
  padding-right: ${({ theme }) => theme.spacing(3)};
  text-align: center;
  max-width: 538px;
  height: 302px;
  background-image: url(${cloud});
  background-position: center center;
`;

const Content = styled(Stack).attrs({ direction: 'column' })`
  background-color: ${({ theme }) => theme.primary};
  color: #ffffff;
  border-radius: 2px;
  padding: ${({ theme }) => theme.spacing(2)};
  max-width: 35em;

  ${({ index }) =>
    index === 1 &&
    css`
      background: ${({ theme }) => theme.tertiary};
    `}

  ${({ theme }) => theme.media.medium`
    max-width: 350px;
  `}

  ${({ isLastElement }) =>
    isLastElement &&
    css`
      background-color: ${({ theme }) => theme.tertiary};
      ${({ theme }) => theme.media.medium`
        max-width: 500px;
      `}

      ${({ theme }) => theme.media.tablet`
      transform: translateX(-9px);
      `}

      ${({ theme }) => theme.media.medium`
      transform: translateX(0px);
      `}
    `}
`;

const KeyDate = styled.div`
  display: grid;
  grid-template-columns: 3em 1fr;
  grid-template-areas:
    'timeline image'
    'timeline description';
  grid-gap: ${({ theme }) => theme.spacing()};
  max-width: 40em;
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.media.medium`
    max-width: none;
    grid-template-columns: 1fr 200px 1fr;
    grid-template-areas: 'image timeline description';

    ${({ isOdd }) =>
      isOdd &&
      css`
        grid-template-areas: 'description timeline image';
      `}
  `};
`;

const Picture = styled.img`
  width: 14em;

  ${({ theme, index }) =>
    index !== 1 &&
    theme.media.medium`
    width: 350px;
  `}
`;

const Timeline = styled.div`
  height: 100%;
  width: 2px;
  background-color: ${({ theme }) => theme.tertiary};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    border: 12px solid transparent;
    left: 2px;
    border-left-color: ${({ theme }) => theme.tertiary};
    top: ${({ theme }) => theme.spacing(4)};

    ${({ theme }) => theme.media.medium`
      transform: translateY(-50%);
      top: 50%;
    `}
  }

  ${({ theme, position }) =>
    position === 'left' &&
    theme.media.medium`
      &:before {
        left: auto;
        right: 2px;
        border-left-color: transparent;
        border-right-color: ${({ theme }) => theme.tertiary};
      }
    `};
`;

const HistorySection = ({ data }) => {
  return (
    <Section backgroundColor="yellowLight" id="histoire">
      <IntersectionSensor section="#histoire">
        <Stack alignX="center" size="full">
          <Heading>
            <Overtitle>{data?.historyOvertitle}</Overtitle>
            <Subtitle>{data?.historyTitle}</Subtitle>
            <Text type="bodyLarge">{data?.historySubtitle}</Text>
          </Heading>
        </Stack>

        <Container>
          <Stack direction="column" paddingHorizontal={1}>
            {(data?.keyDates || []).map(({ date, content, picture }, index) => {
              const isOdd = index % 2 === 1;

              if (data.keyDates.length - 1 === index) {
                return (
                  <Stack
                    alignX={{ default: 'flex-start', tablet: 'center' }}
                    key={index}
                  >
                    <Content isLastElement>
                      <Text type="h2" fontFamily="body">
                        {date}
                      </Text>
                      <div dangerouslySetInnerHTML={{ __html: content }} />
                    </Content>
                  </Stack>
                );
              }

              return (
                <KeyDate isOdd={isOdd} key={index}>
                  <Stack
                    alignX={{
                      default: 'flex-start',
                      medium: isOdd ? 'flex-end' : 'flex-start',
                    }}
                    paddingBottom={{ default: 4, medium: 2 }}
                    paddingTop={{ default: 0, medium: 2 }}
                    gridArea="description"
                  >
                    <Content
                      transform={{
                        default: `rotate(${isOdd ? -1.5 : -0.75}deg)`,
                        medium: `rotate(${isOdd ? 3 : -3}deg)`,
                      }}
                      index={index}
                    >
                      <Text type="h2" fontFamily="body">
                        {date}
                      </Text>
                      <div dangerouslySetInnerHTML={{ __html: content }} />
                    </Content>
                  </Stack>

                  <Stack gridArea="timeline" alignX="center">
                    <Timeline position={isOdd ? 'left' : 'right'} />
                  </Stack>

                  <Stack
                    alignX={{
                      default: 'flex-start',
                      medium: isOdd ? 'flex-start' : 'flex-end',
                    }}
                    paddingTop={2}
                    paddingBottom={{ default: 0, medium: 2 }}
                    alignY="center"
                    gridArea="image"
                  >
                    <Zoom>
                      <Picture
                        src={picture?.url}
                        alt={picture?.alt}
                        index={index}
                      />
                    </Zoom>
                  </Stack>
                </KeyDate>
              );
            })}
          </Stack>
        </Container>
      </IntersectionSensor>
    </Section>
  );
};

export default HistorySection;
